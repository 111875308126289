.container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.titleText {
  font-family: "Aeonik TRIAL";
  font-size: 24px;
  letter-spacing: 0.5px;
  font-weight: 900;
  color: #44444f;
}

.titleTextTwo {
  font-family: "Aeonik TRIAL";
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 900;
  color: #44444f;
}

.containerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.hightlight {
  background-color: #cef2ff;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.test {
  height: 80px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  list-style-type: none;
}

li {
  border-top: 1px solid #ccc;
}

li:last-child {
  border-bottom: 1px solid #ccc;
}

.percent {
  font-family: "Aeonik TRIAL";
  font-size: 24px;
  font-weight: 800;
  line-height: 27.36px;
  letter-spacing: 0.07em;
  color: #01b9ff;
}

.containerFour {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.textTwo {
  font-family: "Aeonik TRIAL";
  font-size: 16px;
  font-weight: 400;
  line-height: 18.24px;
  letter-spacing: 0.07em;
  color: #838383;
}

.code {
  font-family: "Aeonik TRIAL";
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;

  text-decoration: underline;
  text-underline-offset: 2px;
  color: #e1e1e1;

  text-align: center;
  margin-top: 20px;
}
.usedCode {
  opacity: 0.5;
  pointer-events: none;
}


@media (max-width: 700px) {
  .titleTextTwo {
    font-size: 15px; 
  }

  .percent {
    font-size: 18px;
  }

  .containerFour {
    column-gap: 10px;
  }
}