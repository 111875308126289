.container {
  padding: 20px 40px;
  padding-top: 120px;  /* To offset the fixed header height*/

  /* background-image: url("/public/LightBlueSpiral.svg"); */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  background-image: url("/public/img/lightBg.svg");
  height: 100vh;
  margin-bottom: 100px;
  
}

.headerText {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
}

.textOne {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
} 

.cardContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.textTwo {
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.containerTwo {
  margin-top: 20px;
  height: 140px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.textThree {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

@media (max-width: 800px) {  
  .cardContainer {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
  }

  .headerText {
    font-size: 30px;
    line-height: 30px;
  }

  .img {
    width: 90%;
  }

  .textThree {
    text-align: center;
  }
}