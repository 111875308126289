.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
}

.label {

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* grey-6 */

  color: #344054;
  margin-bottom: 5px;
}

.select {
  border: 0.5px solid #D0D5DD;
  border-radius: 6px;
  outline: none;
  height: 50px;

  padding-left: 10px;

  font-size: 20px;
  font-weight: normal;
  color: #8A94A6;

  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
}

.container::after {
  content: "\2304";
  font-size: 25px;
  line-height: 23px;
  color: #D0D5DD;

  position: absolute;
  right: 15px;
  top: 35px;
}

@media screen and (max-width: 700px) {
  .container {
    /* height: 90%; */
  }
  .select {
    /* height: 40px; */
    font-size: 16px;
  }

  .container::after {
    top: 30px;
  }
  .label {
    font-size: 16px;
  }
}
