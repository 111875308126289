.drawer, .drawerOpen {
  position: fixed;
  top: 0;
  left: -100%; /* Initially off-screen */
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease-in-out;
}

/* Slide-in effect */
.drawerOpen {
  transform: translateX(100%);
}


.drawerContent {
  padding: 30px;
  height: 500px;
  width: 50vw;
  background-color: white;
  border-radius: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  overflow-y: scroll;
  scrollbar-color: #3873FF white;
  scrollbar-width: thin;

}

.cancel {
  position: absolute;
  right: 20px;
  top: 30px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}


.textOne {
  font-family: "Museo Slab";
  font-size: 30px;
  font-weight: 500;
  line-height: 28.8px;
  color: #183887;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 600;
  line-height: 20.8px;
  color: #183887;
  margin-bottom: 10px;
}

.textThree {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #183887;
  margin-bottom: 10px;
}


.listContainer {
  list-style-type: circle;
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 10px;
  margin-left: 20px;
}

.textFour {
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.textSeven {
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.textFour::before {
  content: '';
  position: absolute;
  bottom: 7px;
  left: -10px;
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  }

.textFive {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #183887;
  margin-bottom: 10px;
  margin-top: 10px;
}

.link {
  color: blue;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  width: 90%;
}

.link:hover {
  color: darkblue;
  text-decoration: underline;
}

.containerText {
  margin-top: 20px;
}

.textSix {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 1000px) {  

  .drawerContent {
    width: 80vw;
    row-gap: 20px;
  }

  .textOne {
    font-size: 25px;
  }

  .textTwo, .textThree, .textFive, .textSix {
    font-size: 16px;
  }

  .textFour, .textSeven {
    font-size: 14px;
  }

}
