/* Drawer container */
.drawer, .drawerOpen {
  position: fixed;
  top: 0;
  right: -100%; /* Initially off-screen */
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: transform 0.5s ease-in-out;
}

/* Slide-in effect */
.drawerOpen {
  transform: translateX(-100%);
}

/* Drawer content */
.drawerContent {
  padding: 30px;
  height: 100vh;
  position: relative;
}

/* Close button */
.closeBtn {
  background: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Below drawer */
}

.backText {
  font-size: 18px;
  font-weight: 600;
  color: #142657;
  font-family: "Aeonik";
  margin-top: 2px;
}

.backContainer {
  display: flex;
  column-gap: 15px;
  height: 40px;
  align-items: center;
  cursor: pointer;
  width: max-content;
}


.logoContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.logo {
  width: 25px;
  height: 25px;
}

.containerText {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 40px;
}

.textOne, .textOneActive {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #1E1E1E;
}

.textOneActive {
  color: #183887;
}


.textThree {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  cursor: pointer;
}

.textContainer {
  display: flex;
  column-gap: 30px;
}

.btnContainer {
  display: flex;
  column-gap: 30px;
}

.btnLogin {
  width:  100px;
  height: 45px;
  color: white;
  background-color: #183887;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.btnLoginOutlined {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #3873FF;
  border: 1px solid #3873FF;

  width:  100px;
  height: 45px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}


.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  position: absolute;
  bottom: 50px;
  left: 30px;
}