* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.businessdashboard__content {
  display: grid;
  grid-template-columns: 1.3fr 5fr;
  grid-template-areas: "sidebar main";
  height: 100%;
  margin-top: 5em;
}
