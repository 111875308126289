.container {
  height: 100vh;
  width: 100%;
  background-color: white;
  overflow-y: scroll;
}

.header {
  height: 80px;
  width: 100%;

  box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.66);
  -webkit-box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.66);
  -moz-box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.66);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}

.containerTwo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.textOne {
  font-family: 'Museo Slab';
  color: #1B4DF5;
  font-size: 20px;
}

.containerThree {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.textTwo {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #1E1E1E;
  cursor: pointer;
}

.textThree {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  cursor: pointer;
}

.containerFour {
  display: flex;
  align-items: center;
  width: max-content;
  column-gap: 20px;
}

.btnLogin {
  width:  100px;
  height: 45px;
  color: white;
  background-color: #183887;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.btnLoginOutlined {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #3873FF;
  border: 1px solid #3873FF;

  width:  100px;
  height: 45px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.menuBar {
  display: none;
}

.sliderContainer {
  height: min-content;
  display: flex;
  justify-content: center;

  margin: 40px auto;
  position: relative;
  width: 80vw;
}

.btnShop {
  position: absolute;
  bottom: 10%;
  right: 20px;


  height: 40px;
  width: 100px;
  background-color: white;
  outline: none;
  border: none;
  border: 1px solid #1B4DF5;
  border-radius: 5px;

  color: #3873FF;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.slider {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0px auto;
  position: relative;
}

.hiddenHeight {
  height: 100px;
  width: 10px;

  display: none;
}

.testCont {
  width: min-content;
  overflow-x: scroll;
  display: flex;
  column-gap: 20px;
  padding: 20px;
  scrollbar-width: none;
}

.testContTwo {
          /* width: "90vw",
        overflowX: "scroll" */
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;      
}

@media (max-width: 1300px) {

  .sliderContainer {
    /* width: 90vw; */
    height: 400px;
  }

  .btnShop {
    right: 50px;
  }
}

@media (max-width: 1000px) {
  .menuBar {
    display: block;
    height: 30px;
    width: 30px;
  }

  .btnShop {
    right: 50px;
    bottom: -15%;
  }

  .hiddenHeight {
    display: block;
  }

  .containerFour, .textTwo {
    display: none;
  }

  .header {
    padding: 0px 30px;
  }

  .slider {
    height: 400px;
  }

  .sliderContainer {
    height: 300px;
  }
}

@media (max-width: 900px) {
  .btnShop {
    right: 50px;
    bottom: -5%;
  }
  
}


@media (max-width: 800px) {
  .sliderContainer, .slider {
    height: 200px;
  }

  .hiddenHeight {
    display: none;
  }

  .btnShop {
    right: 15%;
    bottom: 10%;
  }
  
}

@media (max-width: 500px) {
  .slider {
    margin-top: -20px;
  }

  .btnShop {
    right: 15%;
    bottom: 35%;

    height: 35px;
    width: max-content;
    padding: 0px 10px;
    font-size: 12px;
  } 
}


@media (max-width: 350px) {
  .btnShop {
    right: 5%;
    bottom: 40%;
  } 
}
