.container {
  padding: 20px;

  height: 500px;

  width: 100%;
  background-color: white;

  display: flex;
  justify-content: space-between;
  padding-top: 60px;
}

.partOne {
  width: 50%;
  height: 100%;

  display: flex;
  justify-content: center;
}

.content {
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.hero__section__text {
  font-size: 50px;
  font-weight: 500;
  line-height: 45px;
  font-family: "Museo";
  color: #141D57;
}

.smallText {
  font-size: 18px;
  letter-spacing: .5px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Aeonik";
  color: #667085;
}

.tickIcontext {
  display: flex;
  column-gap: 10px;
}

.tickIcon {
  height: 20px;
  width: 20px;
}

.contentThree {
  margin: 10px 0px;
}


.partTwo {
  width: 50%;
  height: 400px;

  display: flex;
  justify-content: center;
}

.partTwoInner {
  width: 80%;
  height: 90%;

  display: flex;
  justify-content: center;
}

.image36 {
  width: 100%;
  height: 100%;
}


/* Responsive adjustments */
@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    height: max-content;
  }

  .partOne {
    width: 100%;
    justify-content: flex-start;
  }

  .content {
    width: 100%;
  }

  .hero__section__text {
    width: 100%;
    font-size: 35px;
  }

  .partTwo {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }

  .partTwoInner {
    width: 90%;
    height: 90%;
  }
}