.container {
  height: max-content;
  width: 100%;

  padding: 20px;
  background-color: white;
}

.hero__section__text {
  font-size: 50px;
  font-weight: 500;
  line-height: 45px;
  margin: 30px 0px;
  font-family: "Museo";
  color: #142657;

  text-align: center;
}

.content {
  width: 90%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

.contentItem {
  width: 30%;
  height: 250px;

  border: 1px solid #D7DAE0;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  padding: 20px 10px;
}

.contentTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Museo";
  color: #142657;
}

.smallText {
  font-size: 18px;
  letter-spacing: .5px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Aeonik";
  color: #667085;
  text-align: center;
}

.contentImg {
  height: 40px;
  width: 40px;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .content {
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
  }

  .smallText {
    width: 80%;
  }

  .contentItem {
    width: 100%;
  }

  .hero__section__text {
    font-size: 35px;

  }
}
