.container {
  height: 45px;
  width: 350px;
  background-color: white;
  border: 1px #ccc solid;
  border-radius: 20px;

  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0px 20px;
  cursor: pointer;
  position: relative;
}

.textOne {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.30000001192092896px;
}

.listContainer {
  position: absolute;
  top: 45px;
  left: 0px;

  height: 200px;
  width: 350px;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: white;
  border: 1px #ccc solid;
  border-radius: 6px;

  padding: 20px;

  scrollbar-color: #3873FF white;
  scrollbar-width: thin;
  z-index: 20;
}


.item {
  transition: all .4s;
  padding: 4px;
}

.item:hover {
  background-color: #d4e9f1;
}

@media (max-width: 700px) {
  .container, .listContainer {
    width: 95%;
  }
}