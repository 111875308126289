.container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 20px;
}

.titleText {
  font-family: 'Aeonik TRIAL';
  font-size: 24px;
  letter-spacing: .5px;
  font-weight: 900;
  color: #44444F;
}

.titleTextTwo {
  font-family: 'Aeonik TRIAL';
  font-size: 16px;
  font-weight: 900;
  color: #44444F;
}

.containerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.hightlight {
  background-color: #CEF2FF;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.test {
  height: 80px;  
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  list-style-type: none;
}

li {
  border-top: 1px solid #ccc;
}

li:last-child {
  border-bottom: 1px solid #ccc;
}

.percent {
  font-family: 'Aeonik TRIAL';
  font-size: 24px;
  font-weight: 800;
  line-height: 27.36px;
  letter-spacing: 0.07em;
  color: #01B9FF;
}

.containerFour {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.textTwo {
  font-family: 'Aeonik TRIAL';
  font-size: 16px;
  font-weight: 400;
  line-height: 18.24px;
  letter-spacing: 0.07em;
  color: #838383;
}

.code {
  font-family: 'Aeonik TRIAL';
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  color: #3873FF;
  border-radius: 6px;
  margin-top: 20px;

  background-color: #CEF2FF;
}

.money {
  font-family: 'Aeonik TRIAL';
  font-size: 20px;
  font-weight: 800;
  line-height: 17.1px;
}

.success {
  font-family: 'Aeonik TRIAL';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.07em;
  color: white;
  background-color: #3873FF;
  padding: 3px 10px;
  text-align: center;
  border-radius: 3px;
  margin-top: 3px;
}


@media (max-width: 700px) {
  .money {
    font-size: 15px;
  }

  .test {
    padding: 20px 10px;
  }

  .container {
    padding: 20px 10px;
  }

  .containerFour {
    column-gap: 10px;
  }
  
}