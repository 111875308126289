

.container {
  padding: 20px 40px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */

  background-color: white;
  min-height: 100vh;
  /* overflow-y: scroll; */
}

.header {
  height: 50px;
  width: 100%;

  display: flex;

  justify-content: end;
  align-items: center;
}


@media (max-width: 1300px) {  
  .header {
    justify-content: start;
  }
}