.container {
  width: 100%;
  height: min-content;
  background-color: #183887;
  border-radius: 15px;
  padding: 40px;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
  color: white;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  
  color: white;
}

.formContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 30px;
}

.formGroup option {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.label {
  display: block;
  margin-bottom: 0.5em;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.select, .input {
  width: 100%;
  height: 44px;
  padding: 0.75em;
  font-size: 1em;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  outline: none;
}

.calculateButton {
  width: 100%;
  height: 44px;
  padding: 0.75em;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  background-color: #3386fe;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1em;
}

.calculateButton:hover {
  background-color: #0056b3;
}

.resultContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 0.75em;
  border-radius: 5px;
  margin-top: 20px;
}

.result {
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

.clearButton {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  font-size: 1em;
}

.clearButton:hover {
  background-color: #eee;
}

@media (max-width: 800px) {  
  .container {
    padding: 20px;
  }

  .textOne {
    font-size: 25px;
  }

  .textTwo {
    font-size: 16px;
  }
}
