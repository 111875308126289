.container {
  padding: 20px 30px;

  background-color: #ffffff;
  height: 100vh; 
  width: 21%;

  padding-top: 120px;  /* To offset the fixed header height*/

  position: fixed;
  left: 0px;
  top: 0px;
  overflow-y: scroll;
}

.textprofile {
  font-family: 'Museo Slab';
  color: #44444F;
  font-size: 24px;
  font-weight: 550;
  letter-spacing: .5px;
}

.textContainer {
  margin-top: 10px;
}

.idText {
  font-family: 'Aeonik TRIAL';
  font-size: 12px;
  font-weight: 400;
  line-height: 13.68px;
}

.upgradetext {
  font-family: 'Aeonik TRIAL';
  color: #CD7F32B2;
  font-size: 15px;
  cursor: pointer;
  transition: all .4s;
}

.upgradetext:hover {
  color: #e2b383b2;
}

.span {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.date {
  color: #FF000099;
  font-family: 'Aeonik TRIAL';
  font-size: 15px;
}

.navContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.navContainerTwo {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  position: absolute;
  bottom: 40px;
  left: 30px;
}

.navtext, .navtextActive {
  font-family: 'Museo Slab';
  font-size: 18px;
  letter-spacing: .2px;
  font-weight: 600;
  line-height: 24px;
}

.navtextActive {
  color: #3873FF;
}

.logoutText {

  font-family: 'Museo Slab';
  font-size: 18px;
  letter-spacing: .2px;
  font-weight: 600;
  line-height: 24px;
  color: #FF0000;

}

.navItem {
  display: flex;
  column-gap: 10px;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .container {  
    display: none;
  }
  
}