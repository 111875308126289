.container {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(55, 55, 55, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
}

.containerTwo {
  width: 500px;
  height: 250px;

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 1rem
}

.textOne {
  font-family: 'Museo Slab';
  font-size: 36px;
  font-weight: 400;
  line-height: 35px;
}

.btnContainer {
  display: flex;
  column-gap: 30px;
  justify-content: center;
}

.btnContained, .btnOutlined {
  width: 120px;
  height: 40px;

  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.btnContained {
  color: white;
  background-color: #FF0000;
}

.btnOutlined {
  border: 1px #3873FF solid;
  color: #3873FF;
  background-color: white;
}

@media (max-width: 700px) {
  .containerTwo {
    width: 90%;
  }

  .btnContained, .btnOutlined {
    width: max-content;
    padding: 0px 10px;
    height: 40px;
  }

  .textOne {
    font-size: 25px;
  }
  
  
}
