.container {
  padding: 20px;
  
  width: 100%;
  background-color: white;
  height: max-content;
  
}

.backContainer {
  display: flex;
  column-gap: 15px;
  height: 40px;
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin-left: 30px;
}

.backText {
  font-size: 18px;
  font-weight: 600;
  color: #142657;
  font-family: "Aeonik";
  margin-top: 2px;
}

.mainForm {
  height: max-content;

  width: 80%;
  margin: 20px auto;
}

.containerTwo {
  padding-bottom: 20px;
  border-bottom: 2px solid #D7DAE0;
  margin-bottom: 20px;
}

.titleText {
  font-family: "Museo";
  color: #142657;
  font-size: 30px;
  font-weight: 600;
}

.logoText {
  font-family: "Aeonik";
  color: #667085;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}
.logoTextTwo {
  font-family: "Aeonik";
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.smallText {
  font-family: "Aeonik";
  color: #667085;
  font-size: 20px;
  font-weight: 500;
}

.containerThree {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

}

.containerFour {
  margin-top: 20px;
  height: 200px;
  width: 100%;
}

.partOne {
  width: 50%;
  height: 70px;
  position: relative;
}

.waitListBtn {
  background-color: #3386fe;
  color: #fff;
  padding: 0.9em 1em;
  border-radius: 8px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  text-align: center;
  cursor: pointer;
  width: max-content;

  margin: 0 auto;

  margin-top: 30px;
}

.closeInput {
  position: absolute;
  top: 5px;
  right: 0px;

  height: 15px;
  width: 15px;
  cursor: pointer;
  z-index: 10;
}

.errorContainer {
  width: 100%;
  height: 80px;

  background-color: #FEF3F2;
  border-radius: 8px;

  padding: 0px 20px;

  display: flex;
  align-items: center;
  column-gap: 20px;
}

.errorText {
  color: #667085;
  font-size: 16px;
  font-weight: 600;

  font-family: "Aeonik";

}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.successContainer {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.successText {
  margin: 0;
}


/* Responsive adjustments */
@media (max-width: 1000px) {
  .containerThree {
    flex-direction: column;
    row-gap: 20px;
  }

  .partOne {
    width: 100%;
  }

  .mainForm {
    width: 90%;
  }

  .container {
    padding: 20px 10px;    
  }
}