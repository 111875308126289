.container {
  height: 500px;
  width: 100%;

  padding: 20px;
  background-color: #1B4DF5;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.textContainer {
  z-index: 5;
  height: max-content;
  width: 80%;
  position: relative;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
}

.spiral__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover; 
  height: 100%;
  z-index: 1;
}

.hero__section__text {
  font-size: 50px;
  font-weight: 500;
  line-height: 45px;
  font-family: "Museo";
  color: white;
  text-align: center;
}

.smallText {
  font-size: 18px;
  letter-spacing: .5px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Aeonik";
  color: white;
  text-align: center;

  width: 50%;
}

.smallTextTwo {
  font-size: 18px;
  letter-spacing: .5px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Aeonik";
  color: white;
  text-align: center;
}

.content {
  width: 90%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  z-index: 5;
  position: relative;
}

.contentItem {
  width: 30%;
  height: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  padding: 0px 10px;
}

/* Responsive adjustments */
@media (max-width: 1000px) {

  .container {
    height: max-content;
  }

  .hero__section__text {
    font-size: 35px;
  }

  .textContainer {
    margin-top: 40px;
  }

  .content {
    flex-direction: column;
    row-gap: 20px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .contentItem {
    width: 100%;
  }

  .smallText {
    width: 100%;
  }
}