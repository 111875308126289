.faqs-page {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 20px 0px;
}

.faqs-container {
  flex: 1;
  padding: 1.5em;
  background-color: #fff;
  margin-left: 5em;
}

.faqs-header {
  font-size: 40px;
  font-weight: 500;
  font-family: "Museo";
  text-align: left;
  color: #142657;
}

.faq_href {
  text-decoration: none;
  font-style: italic;
  color: red;
}

.faq-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 90%;;
}

.faq-item.active {
  max-height: min-content;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #142657;
  font-family: "Open Sans", sans-serif;
}

.faq-arrow {
  font-size: 1em;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: #142657;
  padding-top: 0.5em;
  opacity: 0;
  visibility: hidden;
}

.faq-item.active .faq-answer {
  max-height: max-content; 
  opacity: 1;
  visibility: visible;
}


.faqshero__container {
  background: #142657;
  position: relative;
  height: 444px;
  width: 100%;
  margin-top: 12em;
}

.faqs__spiral {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  object-fit: cover;
  height: 444px;
}

.faqshero__content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  margin-top: -3em;
  margin-bottom: 3em;
  margin-left: 3em;
}

.faqs__cards_i {
  margin-left: -19em;
}

.faqshero__details {
  margin-top: -19em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.faqshero__details_i {
  margin-top: -7em;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.faqshero__input {
  margin-top: -8em;
  width: 440px;
  height: 44px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding-left: 2em;
  position: relative;
  z-index: 3;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .faqs-container {
    margin-left: 2em;
    padding-top: 0px;
  }

  .faqs-header {
    font-size: 30px;
  }
}
