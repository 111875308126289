.containerTwo {
  margin-top: 20px;
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
}

.sideLogoContainer, .formContainer {
  display: flex;
  justify-content: center;
}

.formInner {
  width: 600px;
  height: min-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.textOne {
  font-family: 'Museo';
  font-size: 28px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  margin-bottom: 10px;
}

.textTwo {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #4B4B4B;
}

.input, .inputPassword, .inputTwo {
  border-radius: 15px;
  outline: none;
  height: 45px;
  padding: 0px 10px;

  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: #5c5b5b;

  border: 0.5px solid #CCCCCC;
  padding-left: 20px;
}

.inputTwo {
  width: 50%;
}

.input {
  width: 80%;
}

.inputPassword {
  width: 100%;
}


.checkBox, .checkBoxTwo {
  width: 20px;
  height: 20px;

  border: 1px #E0E0E0 solid;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxTwo {
  cursor: pointer;
}

.boxBlue {
  background-color: #3873FF;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.textThree {
  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #B3B3B3;
}

.remContainer {
  display: flex;
  column-gap: 10px;
  width: 80%;
  align-items: center;
}

.btn {
  height: 50px;
  width: 80%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;
}

.containerThree {
  width: 80%;
  position: relative;
}

.eyeIcon {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.textFour {
  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  width: 80%;
  color: #B3B3B3;
}

.spanText {
  color: #3873FF;
  cursor: pointer;
  font-weight: 600;
}

.spanTextTwo, .spanTextThree {
  color: #3873FF;
  cursor: pointer;
  font-weight: 500;
}

.spanTextThree {
  width: 80%;
}

.dropDownItem {
  width: 80%;
  height: 45px;
}

.containerFour {
  width: 80%;
  display: flex; 
  column-gap: 20px;
}

.input:-webkit-autofill,
.input:-webkit-autofill:focus,
.inputPassword:-webkit-autofill,
.inputPassword:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

@media (max-width: 1300px) {  
  .header {
    justify-content: start;
  }

  .container {
    padding: 20px 20px;
  }

  .containerTwo {
    grid-template-columns: 1fr;
  } 

  .sideLogoContainer {
    display: none;
  }

  .formInner {
    width: 100%;
  }

  .input, .containerThree, .btn, .forgotPassword, .containerFour, 
  .textFour, .containerThree, .remContainer, .spanTextThree, .dropDownItem {
    width: 100%;
  }
}

@media (max-width: 700px) {  

  .containerFour {
    flex-direction: column;
    row-gap: 20px;
  }

  .inputTwo {
    width: 100%;
  }
}