* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.businessheader__content {
  display: flex;
  justify-content: flex-start;
  margin-left: 3em;
}

.businessheader__context {
  display: flex;
  justify-content: flex-end;
  margin-right: 3em;
}

.businessheader__text {
  font-family: "Museo Slab";
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.dropdownicon {
  margin-left: 1em;
}

.header {
  height: 80px;
  background-color: #ffffff;
  width: 100%;
  z-index: 10;

  position: fixed;
  left: 0px;
  top: 0px;

  box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.75);

  padding: 0px 30px;
}

.businessheader__content {
  display: flex !important;
  justify-content: space-between;
}

.menuBar {
  display: none;
}

.businessheader__img {
  display: flex;
  justify-content: flex-start;
}

.verifysidebar__context {
  display: flex;
  padding-left: 3em;
  padding-top: 0.5em;
  justify-content: flex-end !important;
  margin-top: 1.5em;
}

.verifysidebar__verify {
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 600;
  color: #3873ff;
  padding-bottom: 1em;
}

.verifysidebar__verify {
  padding-left: 10px;
}

.businesslogo {
  width: 135px;
}

@media (max-width: 800px) {
  .sliderContainer,
  .slider {
    height: 200px;
  }

  .hiddenHeight {
    display: none;
  }

  .btnShop {
    right: 15%;
    bottom: 10%;
  }
}

@media (max-width: 1000px) {
  .slider {
    margin-top: -20px;
  }

  .btnShop {
    right: 15%;
    bottom: 35%;

    height: 35px;
    width: max-content;
    padding: 0px 10px;
    font-size: 12px;
  }

  .businessheader__container {
    display: none;
  }

  .header {
    margin-top: 1em;
  }

  .menuBar {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    width: 40px;
    margin-left: 11em;
  }

  .verifysidebar__context {
    display: none;
  }
}

@media (max-width: 1300px) {
  .slider {
    margin-top: -20px;
  }

  .btnShop {
    right: 15%;
    bottom: 35%;

    height: 35px;
    width: max-content;
    padding: 0px 10px;
    font-size: 12px;
  }

  .businessheader__container {
    display: none;
  }

  .header {
    margin-top: 1.5em;
  }

  .menuBar {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    width: 40px;
    margin-left: 11em;
  }

  .verifysidebar__context {
    display: none;
  }
}

@media (max-width: 500px) {
  .slider {
    margin-top: -20px;
  }

  .btnShop {
    right: 15%;
    bottom: 35%;

    height: 35px;
    width: max-content;
    padding: 0px 10px;
    font-size: 12px;
  }

  .businessheader__container {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
  }

  .menuBar {
    display: flex;
    justify-content: flex-end;
    width: 40px;
    margin-left: 11em;
  }

  .verifysidebar__context {
    display: none;
  }
}

@media (max-width: 350px) {
  .btnShop {
    right: 5%;
    bottom: 40%;
  }

  .businessheader__container {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
  }

  .menuBar {
    display: flex;
    justify-content: flex-end;
    width: 40px;
    margin-left: 11em;
  }

  .verifysidebar__context {
    display: none;
  }
}
