

.footerBus {
  background-color: #1B4DF5;
  padding: 10px 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 300px;

  background-image: url("/public/DarkBlueSpiral.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.footerdark__spiral {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.footer__container {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  padding-top: 30px;
}

.footer__first__content {
  display: flex !important;
  justify-content: space-between;
  flex-direction: column !important;
  position: relative;
  z-index: 2;

  padding-left: 20px;
}

.footer__first__content_i {
  display: flex;
}

.footer_brand {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding-top: 7px;
}

.footer__text {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.footer__newway {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding-left: 2em !important;
  padding-top: 1em !important;
  text-align: left !important;
}

.footer__privacy__content {
  display: flex;
  margin-top: 1em;
}

.footer__privacy__text {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding-top: 1em;
  padding-right: 15px;
}

.footer__privacy__terms {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding-left: 15px !important;
  padding-top: 1em !important;
}

.footer__second__content {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  margin-right: 5em;
  z-index: 2;
}

.footer__second__text {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.footer__second__context {
  display: flex;
  margin-top: 2em;
}

.alpha__img {
  margin-right: 2em;
}

.oats__img {
  margin-left: 1em;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .footer__container {
    /* position: relative;
    z-index: 2;
    margin-top: 2em;
    display: flex;
    justify-content: space-between; */
    flex-direction: column;
    row-gap: 20px;
  }

  .footer, .footerBus { 
    height: max-content;
  }

  .footerdark__spiral {
    height: 100%;
  }

  .footer__second__content {
    margin-left: 2em;
  }  
  
}