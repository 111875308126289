.container {
  padding: 20px 40px;
  padding-top: 120px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */
  grid-column: 2/3;
}

.headertext {
  font-family: 'Museo Slab';
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #44444F;
}

.contentMain {

  height: 500px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  row-gap: 20px;
}


.textOne {
  font-family: 'Aeonik TRIAL';
  font-size: 20px;
  font-weight: 700;
  line-height: 22.8px;
}

.img {
  height:290px; 
  width:290px;
}


@media (max-width: 1300px) {
  .container {
    grid-column: 1/3;
    padding: 20px;
    padding-top: 120px; 
  }  
}

@media (max-width: 1100px) {
  .headertext {
    font-size: 25px;
    line-height: 25px;
  }
}

@media (max-width: 800px) {
  .contentMain {
    height: min-content;
    margin-top: 50px;
  }
  .img {
    width: 80%;
    height: 200px
  }
}