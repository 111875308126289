.container {
  width: 100%;
  height: 400px;

  background-color: #183887;

  display: flex;
  margin-top: 20px;
  justify-content: center;
  column-gap: 50px;
  padding: 30px 40px;

}

.itemOne, .itemTwo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  line-height: 45px;
  color: white;
}

.card {
  width: 400px;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
}

@media (max-width: 1000px) {  
  .innerContainer {
    width: 100%;
  }
}

@media (max-width: 950px) {  
  .container {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    height: min-content;
  }

  .itemOne, .itemTwo {
    width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 700px) {  
  .card {
    width: 90%;
  }

  .textOne {
    font-size: 25px;
  }
}