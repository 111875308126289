* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.businessmaincontent {
  background: #eff0f6;
  height: 100%;
  padding-top: 1.5em;
}

.businessmain__context {
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
}

.businessmain__cards {
  width: 171px;
  height: 125px;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #eff0f6;
  display: block;
}

.containerTwo {
  background-color: white;
  height: 80px;
  width: 550px;
  border-radius: 6px;

  border: 1px solid #eeeeee;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
}

.leftSide {
  background-color: #ff8a00;
  width: 5px;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  position: absolute;
  left: 0px;
  top: 0px;
}

.containerFour {
  display: flex;
  column-gap: 20px;
}

.verifyText {
  font-family: "Aeonik";
  letter-spacing: 0.5px;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color: #44444f;
}

.uploadtext {
  font-family: "Aeonik TRIAL";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 14.82px;
  color: #848484;
}

.btn {
  height: 50px;
  background-color: #3873ff;
  width: 140px;
  border-radius: 6px;
  color: white;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}

.btn:hover {
  background-color: #0c2d7c;
}

.businessmain__member {
  color: #000000b2;
  font-family: "Museo Slab";
  font-size: 14px;
  font-weight: 600;
  padding-left: 1.5em;
  padding-top: 1em;
  /* background: #000000B2; */
}

.businessmain__membercount {
  color: #000000;
  font-family: "Museo Slab";
  font-size: 16px;
  font-weight: 600;
  padding-left: 1.5em;
  padding-top: 1em;
}

.graph {
  padding-top: 0.5em;
  margin-left: 1em;
}

.businessmaincard__holder {
  background: #fff;
  width: 600px;
  height: 380px;
  border-radius: 7px;
  border: 1px solid #eff0f6;
}

.businessmaincard__year {
  background: #fff;
  width: 550px;
  height: 413px;
  border-radius: 7px;
  border: 1px solid #eff0f6;
}

.businessmaincard__content {
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
}

.businessmaincard__holder__text {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #e4e5e7; */
}

.businessmaincard__hr {
  width: 90%;
  border: 1px solid #e4e5e7;
  margin-left: 1.5em;
}

.businessmaincard__holder__activity {
  font-family: "Museo Slab";
  font-size: 14px;
  font-weight: 400;
  padding-left: 1.5em;
  padding-top: 1em;
  color: #4d4d4d;
}

.businessmaincard__holder__activity_i {
  font-family: "Museo Slab";
  font-size: 14px;
  font-weight: 400;
  padding-right: 1.5em;
  padding-top: 1em;
  color: #0f2552;
}

.businessmaincard__year__text {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1em;
}

.chev__left {
  margin-left: 1em;
}

.chev__right {
  margin-right: 1em;
}

.year {
  font-family: "Museo Slab";
  font-size: 23px;
  font-weight: 400;
  color: #171725;
}

#apexchartspkyvjabjj {
  width: 400px !important;
  height: 250px !important;
}
