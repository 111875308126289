.container {
  padding: 20px 40px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */

  background-color: white;
  min-height: 100vh;
  /* overflow-y: scroll; */
}

.header {
  height: 50px;
  width: 100%;

  display: flex;

  justify-content: end;
  align-items: center;
}


.btn {
  height: 50px;
  width: 300px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;

  margin: 0 auto;
  margin-top: 40px;

}

.itemContainer {
  height: 45px;
  border: 1px solid #183887;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  cursor: pointer;
  column-gap: 10px;
}


.itemText {
  font-family: 'Museo';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;

  text-align: center;

  margin-top: 30px;
}

.textOne {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  align-items: center;
  margin-top: 40px;
}

.containerTwo {
  /* width: 65%;
  display: flex;
  justify-content: space-between; */

  width: 80%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
}

.containerThree {
  width: 75%;
  display: flex;
  justify-content: space-between;
}

.containerFour {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}


@media (max-width: 1300px) {  
  .header {
    justify-content: start;
  }

  .itemText {
    font-size: 20px;
    line-height: 25px; 
  }
}