* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.businesssignup__container {
  background: #fff;
  height: 100%;
}

.businesssignup__content {
  display: flex;
  justify-content: space-between;
  background: #fff;
  height: 80vh;
}

.businesssignup__firstcontent {
  display: flex;
  justify-content: flex-start;
}

.auth__icon {
  width: 100%;
  height: 650px;
  object-fit: contain;
}

.businesssignup__secondcontent {
  display: flex;
  justify-content: flex-end;
}

.postpaidlogo__icon {
  width: 90px;
  height: 90px;
  object-fit: contain;
}
