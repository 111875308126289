.container {
  padding: 20px;
  height: min-content
}

.textOne {
  font-family: "Museo Slab";
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  color: #183887;
}

.containerTwo {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  column-gap: 50px;
}

.flexOne {
  width: 50%;
  height: min-content;
  display: flex;
  justify-content: center;
}

.imgContainer {
  width: 400px;
  height: 450px;
  position: relative;
}

.flexTwo {
  width: 50%;
  height: min-content;
}

.rect {
  width: 70px;
  height: 20px;
  background-color: #183887;
}

.imageOne {
  position: absolute;
  height: 200px;
  left: 20%;
}

.imageTwo {
  position: absolute;
  height: 250px;
  top: 200px;
  left: 0%;
}

.imageThree {
  position: absolute;
  height: 150px;
  top: 150px;
  right: 0%;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #1e1e1e;
  width: 100%;
}

.innerContainer {
  width: 80%;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media (max-width: 1000px) {  
  .innerContainer {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .containerTwo {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
  }
  .flexTwo, .flexOne {
    width: 90%;
  }

  .flexOne {
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .textOne {
    font-size: 30px;
    line-height: 35px;
  }
  
}

@media (max-width: 500px) {
  .textOne {
    font-size: 25px;
  } 
}

@media (max-width: 500px) {

  .imgContainer {
    width: 90%;
    height: 380px;
  }
  .imageOne {
    height: 150px;
    left: 20%;
  }
  
  .imageTwo {
    height: 200px;
    top: 160px;
    left: 0px;
  }
  
  .imageThree {
    height: 100px;
    top: 120px;
    right: 0%;
  }  
}


@media (max-width: 400px) {
  .imgContainer {
    width: 100%;
  }

  .imageOne {
    left: 20%;
  }
  
  .imageTwo {
    top: 160px;
    left: 0px;
    height: 180px;
  }
  
  .imageThree {
    top: 120px;
    right: 0%;
  }  
}
