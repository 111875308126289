* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.verifysidebar__container {
  background: #fff;
  height: 100%;
}

.verifysidebar__text {
  padding: 2em 0em 0em 3em;
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 500;
}

.verifysidebar__context {
  display: flex;
  padding-left: 3em;
  padding-top: 0.5em;
}

.verifysidebar__verify {
  font-family: "Museo Slab";
  font-size: 15px;
  font-weight: 400;
  color: #3873ff;
  padding-bottom: 1em;
}

.verifysidebar__verify {
  padding-left: 10px;
}

.verifysidebar__content {
  display: flex;
  margin-top: 1.5em;
  margin-left: 3em;
  margin-bottom: 2em;
  cursor: pointer;
}

.verifysidebar__account__text,
.verifysidebar__text__active {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  color: #000;
  padding-left: 15px;
}

.verifysidebar__text__active {
  color: #3873ff;
}

.verifysidebar__account__text_ii {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  color: red;
  padding-left: 10px;
}

.verifysidebar__section {
  margin-top: 27em;
}

@media (max-width: 1000px) {
  .verifysidebar__container {
    display: none;
  }
}

@media (max-width: 1300px) {
  .verifysidebar__container {
    display: none;
  }
}

@media (max-width: 500px) {
  .verifysidebar__container {
    display: none;
  }
}

@media (max-width: 350px) {
  .verifysidebar__container {
    display: none;
  }
}
