.dropzone, .errorInput {
  padding: 20px;
  cursor: pointer;

  margin-top: 10px;

  width: 100px;
  height: 100px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  margin-bottom: 20px;
}

.companyLogo {
  height: 70px;
  width: 100px;
  overflow: hidden;
  /* border-radius: 50%; */
}


.dragging {
  background-color: grey;
}

.uploadInput {
  width: 100%;
  height: 100%;

  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.errorInput {
  border: 4px dashed #F04438;
}

.dropzone {
  border: 4px dashed #DCDCDC;
}

.fileText {
  font-size: 14px;
  font-weight: normal;
  color: #8A94A6;
  width: max-content;


  position: absolute;
  left: 0px;
  bottom: -25px;
}

.errorText {
  color: #F04438;
  font-size: 12px;
  font-weight: normal;
  width: max-content;

  position: absolute;
  left: 0px;
  bottom: -25px;
}