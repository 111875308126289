.container {
  height: 500px;
  width: 100%;
  padding: 40px;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;

  color: #183887;
}

.cardContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.itemContainer {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.cardBox {
  height: 137px;
  width: 137px;
  background-color: black;
  border-radius: 10px;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  color: #1e1e1e;
}

.textThree {
  font-family: "Museo Slab";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #183887;
}

.textFour {
  font-family: "Museo Slab";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3873ff;
}

.containerTwo {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

/* New Styles*/

.container {
  width: 95%;
  height: 75px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  margin: 0px auto;

  margin-top: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.textOne,
.textOneActive {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #1e1e1e;
  cursor: pointer;
}

.textOneActive {
  color: #183887;
}

.link {
  text-decoration: none;
}

.header {
  height: 80px;
  width: 100%;

  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.66);
  -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.66);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}

.containerTwo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.textOne {
  font-family: "Museo Slab";
  color: #1b4df5;
  font-size: 20px;
}

.containerThree {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #1e1e1e;
  cursor: pointer;
}

.textThree {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  cursor: pointer;
}

.containerFour {
  display: flex;
  align-items: center;
  width: max-content;
  column-gap: 20px;
}

.btnLogin {
  width: 100px;
  height: 45px;
  color: white;
  background-color: #183887;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.btnLoginOutlined {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #3873ff;
  border: 1px solid #3873ff;

  width: 100px;
  height: 45px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.alloffers__container {
  margin-top: 1.5em;
  margin-left: 1.5em;
  margin-bottom: 1.5em;
}

.alloffers__container_i {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-left: 1.5em;
}

.alloffers__pack {
  display: flex;
  margin-left: 1.5em;
}

.alloffers__text {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  color: #000;
  padding-left: 3em;
}

.pharmacy__container {
  display: flex;
  justify-content: space-evenly;
  /* flex-wrap: wrap; */
  margin-bottom: 1.5em;
}

.pharmacy__container_i {
  display: flex;
  justify-content: flex-start;
  margin-left: 4em;
  margin-bottom: 1.5em;
}

.pharmacy__content {
  width: 180px;
  height: 180px;
  border-radius: 7px;
  background: #fff;
  border: 1px solid #e6e6e6;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.pharmacy__content_i {
  width: 180px;
  height: 180px;
  border-radius: 7px;
  background: #fff;
  border: 1px solid #e6e6e6;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 3em;
}

.pharmacy__img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  margin-top: 1em;
}

.pharmacy__img_i {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-top: 1em;
}

.pharmacy__context {
  background: #183887;
  height: 90px;
  width: 100%;
  margin-top: 2em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pharmacy__context__text {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding-top: 0.8em;
  text-align: center;
}

.pharmacy__context__text_i {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.pharmacy__img_ii {
  width: 110px;
  height: 110px;
  object-fit: contain;
  margin-top: 1em;
}

.menuBar {
  display: none;
}

@media (max-width: 1000px) {
  .container {
    display: none;
    margin: 0px;
  }
}

.menuBar {
  display: none;
}

.sliderContainer {
  height: min-content;
  display: flex;
  justify-content: center;

  margin: 40px auto;
  position: relative;
  width: 80vw;
}

.btnShop {
  position: absolute;
  bottom: 10%;
  right: 20px;

  height: 40px;
  width: 100px;
  background-color: white;
  outline: none;
  border: none;
  border: 1px solid #1b4df5;
  border-radius: 5px;

  color: #3873ff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.slider {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0px auto;
  position: relative;
}

.hiddenHeight {
  height: 100px;
  width: 10px;

  display: none;
}

@media (max-width: 1300px) {
  .sliderContainer {
    /* width: 90vw; */
    height: 400px;
  }

  .btnShop {
    right: 50px;
  }
}

@media (max-width: 1000px) {
  .menuBar {
    display: block;
    height: 30px;
    width: 30px;
  }

  .btnShop {
    right: 50px;
    bottom: -15%;
  }

  .hiddenHeight {
    display: block;
  }

  .containerFour,
  .textTwo {
    display: none;
  }

  .header {
    padding: 0px 30px;
  }

  .slider {
    height: 400px;
  }

  .sliderContainer {
    height: 300px;
  }
}

@media (max-width: 900px) {
  .btnShop {
    right: 50px;
    bottom: -5%;
  }
}

@media (max-width: 800px) {
  .sliderContainer,
  .slider {
    height: 200px;
  }

  .hiddenHeight {
    display: none;
  }

  .btnShop {
    right: 15%;
    bottom: 10%;
  }
}

@media (max-width: 500px) {
  .slider {
    margin-top: -20px;
  }

  .btnShop {
    right: 15%;
    bottom: 35%;

    height: 35px;
    width: max-content;
    padding: 0px 10px;
    font-size: 14px;
  }

  .alloffers__container {
    overflow-x: scroll;
    width: 95vw;
    scrollbar-width: none;
  }

  .pharmacy__container {
    display: flex;
  }

  .pharmacy__container_i {
    display: flex;
  }

  .pharmacy__content {
    margin: 1em;
    margin-left: 5px;
    margin-right: 5px;
    width: 200px;
  }

  .pharmacy__content_i {
    margin: 1em;
    margin-left: 5px;
    margin-right: 5px;
  }

  .alloffers__pack {
    display: flex;
    overflow-x: scroll;
    width: 95vw;
    scrollbar-width: none;
  }
}

@media (max-width: 350px) {
  .btnShop {
    right: 5%;
    bottom: 40%;
  }

  .alloffers__container {
    overflow-x: scroll;
    width: 95vw;
    scrollbar-width: none;
  }

  .pharmacy__container {
    display: flex;
  }

  .pharmacy__container_i {
    display: flex;
  }

  .pharmacy__content_i {
    margin-top: 1em;
  }

  .pharmacy__content {
    margin: 1em;
    margin-left: 5px;
    margin-right: 5px;
    width: 200px;
  }

  .pharmacy__content_i {
    margin: 1em;
    margin-left: 5px;
    margin-right: 5px;
  }

  .alloffers__pack {
    display: flex;
    overflow-x: scroll;
    width: 95vw;
    scrollbar-width: none;
  }
}

@media (max-width: 950px) {
  .container {
    height: min-content;
  }
  .cardContainer {
    flex-direction: column;
    row-gap: 20px;
    height: min-content;
  }

  .textOne {
    font-size: 24px;
    line-height: 23px;
  }
}
