.container {
  width: 100%;
  height: 400px;

  padding: 20px;

  display: flex;
  justify-content: space-between;
  background-color: white;
}

.contentOne {
  width: 50%;

  height: 100%;

  padding-top: 40px;

  display: flex;
  justify-content: center;
}

.contentTwo {
  width: 50%;
  padding-top: 40px;
}

.hero__section__text {
  font-size: 50px;
  font-weight: 500;
  line-height: 45px;
  font-family: "Museo";
  color: #141D57;
  width: 70%;
}

.smallText {
  font-size: 18px;
  letter-spacing: .5px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Aeonik";
  color: #667085;
  width: 80%;
}

.tickIcontext {
  display: flex;
  column-gap: 10px;
}

.tickIcon {
  height: 20px;
  width: 20px;
}

.contentThree {
  margin: 30px 0px;
}

.waitList_btn {
  background-color: #3386fe;
  color: #fff;
  padding: 0.9em 1em;
  border-radius: 8px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  text-align: center;
  cursor: pointer;
  width: max-content;
}


/* Responsive adjustments */
@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    row-gap: 20px;

    height: max-content;
  }

  .contentOne, .contentTwo {
    width: 100%;
  }

  .contentTwo {
    padding-top: 0px;
  }

  .contentOne {
    justify-content: flex-start;
    
  }

  .hero__section__text {
    width: 100%;
    font-size: 35px;
  }

  .smallText {
    width: 100%;
  }
}
