.container {
  padding: 20px 40px;
  padding-top: 120px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */
  grid-column: 2/3;
}

.headertext {
  font-family: 'Museo Slab';
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #44444F;
}

.containerTwo {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.textOne {
  font-family: 'Aeonik TRIAL';
  font-size: 20px;
  font-weight: 700;
  line-height: 22.8px;
  text-align: left;

  margin: 30px 0px 20px 0px;

}

.testContainer {
  height: min-content;
  width: 1000px;
}

.tableContainer {
  height: 500px;
  width: 900px;

  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px #E0E0E0 solid;

  overflow-y: scroll;
}

.checkBox, .checkBoxTwo {
  width: 20px;
  height: 20px;

  border: 1px #E0E0E0 solid;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxTwo {
  cursor: pointer;
}

.boxBlue {
  background-color: #3873FF;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.tableHeaderText {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.0001em;

  width: 110px;
}

.tableHeaderTextTwo {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.0001em;

  width: 130px;
}

.dateText {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.0001em;

  width: 100px;
}

.tableContent {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 0px 20px;

  position: relative;

  border-bottom: 1px #ccc solid;
}

.tableHeader {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  padding: 0px 20px;
  column-gap: 15px;
  border-bottom: 1px #ccc solid;
}

.tableAction {
  position: absolute;
  right: 10px;
  top: 10px;

  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.0001em;
  color: #3873FF;
  cursor: pointer;
}

.contentText {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0001em;
  color:  rgba(0, 0, 0, 0.75);

  width: 110px;
}

.contentTextTwo {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0001em;
  color:  rgba(0, 0, 0, 0.75);

  width: 130px; 
}

.contextDate {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0001em;
  color:  rgba(0, 0, 0, 0.75);

  width: 100px;
}


.usedText, .pendingText, .expiredText {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  padding: 5px 10px;
  width: min-content;
  border-radius: 5px;
}

.usedText {
  color: #20573D;
  background-color: #F1FFF8;
  border: 1px #B8DBCA solid;
}

.pendingText {
  color: #CD7B2E;
  background-color: #FFF9F2;
  border: 1px #EECEB0 solid;
}

.expiredText {
  border: 1px #EEB4B0 solid;
  background-color: #FFF4F2;
  color: #731912;
}

.slideText {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #3873FF;

  margin-top: 30px;
  display: none;
}


@media (max-width: 1300px) {
  .container {
    grid-column: 1/3;
    padding: 20px;
    padding-top: 120px; 
  }  
}

@media (max-width: 1000px) {
  .testContainer {
    overflow-x: scroll;
    width: 85vw;
    margin-top: 20px;
  }
}

@media (max-width: 1000px) {
  .containerTwo {
    flex-direction: column;
    row-gap: 20px;
  }

  .headertext {
    font-size: 25px;
  }
  .slideText {
    display: block;
    line-height: 20px;
  }
}