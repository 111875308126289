.container, .containerL {
  height: min-content;
  width: 324px;

  border-radius: 17px;

  padding: 20px;
}

.container {
  background-color: white;
  border: 5px solid;
  border-color: #3873FF;
  /* border-image: linear-gradient(45deg, #3873FF, #01B9FF) 1; */
}

.containerL {
  background-color: #3873FF;
  background-image: linear-gradient(to right, #3873FF , #01B9FF);
}

.textOne, .textOneL {

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
  width: max-content;
  padding: 10px 20px;
  border-radius: 10px;
}

.textOne {
  background-color: #F1F1F1;
  /* padding: 20px 40px; */
  color: #000B33;
}

.textOneL {
  color: white;
  background-color: #001C80;
}

.textTwo, .textTwoL {
  margin-top: 20px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 18.16px;
}

.textTwoL {
  color: white;
}

.containerTwo, .containerTwoL {
  margin-top: 20px;
  padding: 20px 0px;
}

.containerTwo {
  border-top: solid 1px #cfd5e7;
  border-bottom: solid 1px #cfd5e7;
}

.containerTwoL {
  border-top: solid 1px #001C80;
  border-bottom: solid 1px #001C80;
}


.textThree, .textThreeL {
  font-family: 'Poppins';
  font-size: 66.47px;
  font-weight: 600;
  line-height: 73.12px;
  letter-spacing: -0.46158626675605774px;
}

.textThree {
  color: #000B33;
}

.textThreeL {
  color: white;
}

.textFour, .textFourL {
  font-family: 'Poppins';
  font-size: 14.77px;
  font-weight: 600;
  line-height: 24px;
}

.textFourL {
  color: white;
}

.containerThree {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.pointsContainer {
  display: flex;
  column-gap: 10px;
}

.textFive, .textFiveL {
  font-family: 'Poppins';
  font-size: 14.77px;
  font-weight: 500;
  line-height: 24px;
}

.textFiveL {
  color: white;
}

.textSix {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #1AA703;

  padding: 0px 2px;
  border-radius: 3px;
  background-color: #C9FAD6;

}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btn, .btnL {
  height: 50px;
  width: 200px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  /* margin-top: 20px; */
}

.btn {
  color: white;
  background-color: #3873FF;
}

.btnL {
  background-color: white;
  color: #3873FF;
}

@media (max-width: 700px) {
  .container, .containerL {
    width: 90%;
  }

  .textThree, .textThreeL {
    font-size: 40px;
    line-height: 73.12px;
  }
  
  
}