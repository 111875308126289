.container {
  padding: 20px;
  height: 600px;
  background-color: white !important;
  /* background-color: white; */
  width: 100%;

  display: flex;
  position: relative;

  /* background-image: url("/public/LightBlueSpiral.svg"); */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.partOne {
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 10; */
}

.partOneInner {
  width: 70%;
  height: 70%;
}

.partTwoInner {
  width: 80%;
  height: 80%;

  display: flex;
  justify-content: center;
}

.partTwo {
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 10; */
}

.spiral__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover; 
  z-index: 1; 
  height: 600px;
}

.hero__section__text {
  font-size: 48px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 30px;
  font-family: "Museo";
  color: #142657;
}

.hero__section__text_i {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #667085;
}


.waitList_btn {
  background-color: #3386fe;
  color: #fff;
  padding: 0.9em 1em;
  border-radius: 8px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  text-align: center;
  cursor: pointer;
  width: max-content;
}

.image25 {
  width: 100%;
  height: 100%;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    row-gap: 10px; 
    height: max-content;
  }

  .hero__section__text {
    font-size: 35px;
  }
  

  .spiral__img {
    height: 100%;
  }

  .partOne {
    width: 100%;
    height: max-content;
    justify-content: flex-start;

    margin-top: 50px;
  }

  .partOneInner {
    width: 100%;
    height: 70%;
  }

  .partTwo {
    width: 100%;
    height: 500px;
  }

  .partTwoInner {
    width: 90%;
    height: 90%;
  }
}
