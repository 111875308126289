.container {
  height: 80px;
  background-color: #ffffff;
  width: 100vw;
  z-index: 10;

  position: fixed;
  left: 0px;
  top: 0px;

  box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.75);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.containerTwo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.textOne {
  font-family: 'Museo Slab';
  color: #1B4DF5;
  font-size: 20px;
}

.textprofile {
  font-family: 'Museo Slab';
  color: #000000;
  font-size: 20px;
}

.containerThree {
  display: flex;
}

.input {
  height: 50px;
  width: 500px;
  outline: none;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: 'Museo Slab';
  border: 1px solid #3873FF;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.input::placeholder {
  color: #B8CDFF;
  font-family: 'Aeonik TRIAL';
  font-weight: 400;
  font-size: 16px;
}

.search {
  height: 50px;
  width: 80px;
  background-color: #1B4DF5;
  cursor: pointer;
  transition: all .4s;

  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.search:hover {
  background-color: #587cf5;
}

.containerFour {
  display: flex;
  column-gap: 20px;
  margin-right: 15px;
}

.containerFive {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;

  position: relative;
}

.listContainer {
  position: absolute;
  top: 55px;
  right: 0px;

  height: min-content;
  padding: 20px 0px;
  width: 300px;

  background-color: white;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.listContainer > :not(:last-child) {
  border-bottom: 1px #ccc solid;
}

.textTwo, .logoutText, .textTwoActive {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-bottom: 10px;
}

.textTwoActive {
  color: #3873FF;
}

.logoutText {
  color: #FF0000;
}

.icon {
  margin-top: 10px;
  display: none;
  padding: 5px;
}


@media (max-width: 1300px) {
  .containerThree {
    display: none;
  }

  .listContainer {
    top: 65px;
  }

  .containerFour {
    column-gap: 10px;
  }

  .container {
    padding: 0px 10px;
  }

  .icon {
    display: block;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 10px;
  }
  .containerTwo {
    column-gap: 5px;
  }

  .listContainer {
    width: 80vw;
  }

  /* .containerFour {
    margin-right: 5px;
  } */
  
}

