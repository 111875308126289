.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  position: relative;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;

  color: #344054;
  margin-bottom: 5px;
}

.textArea, .errorInput {
  border-radius: 8px;
  outline: none;
  height: 45px;
  padding: 10px;

  font-size: 18px;
  font-weight: normal;
  color: #8A94A6;
  height: 80%;
}

.textArea {
  border: 0.5px solid #D0D5DD;
}

.errorInput {
  border: 0.5px solid #F04438;
}

.errorText {
  color: #F04438;
  font-size: 12px;
  font-weight: normal;

  position: absolute;
  left: 0px;
  bottom: -20px;
}



textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 700px) {

  .textArea {
    /* height: 40px; */
    font-size: 16px;
  }

  .label {
    font-size: 16px;
  }
}
