.input {
  transition: all .4s;
  width: 54px;
  height: 54px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 4px
}

.input:focus {
  border: 2px solid #3873FF;
  outline: #3873FF;
}

.input::placeholder {
  color: #A0AEC0;
}

@media (max-width: 500px) {  


  .input {
    width: 16.6%;
  }
  
}