* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.verify__content {
  display: grid;
  grid-template-columns: 1.3fr 5fr;
  grid-template-areas: "sidebar main";
  height: 100%;
  margin-top: 5em;
}

@media (max-width: 1300px) {
  .verify__content {
    display: block;
    height: 100%;
    margin-top: 5em;
  }
}

@media (max-width: 1000px) {
  .verify__content {
    display: block;
    height: 100%;
    margin-top: 5em;
  }
}

@media (max-width: 500px) {
  .verify__content {
    display: block;
    height: 100%;
    margin-top: 5em;
  }
}

@media (max-width: 350px) {
  .verify__content {
    display: block;
    height: 100%;
    margin-top: 5em;
  }
}
