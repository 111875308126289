.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
}

.container::after {
  content: "\2304";
  font-size: 25px;
  line-height: 23px;
  color: #D0D5DD;

  position: absolute;
  right: 15px;
  top: 35px;
}

.containerTwo {
  display: flex;
  column-gap: 5px;
  align-items: center;
  background-color: #1B4DF5;
  border-radius: 5px;
  height: 25px;
  padding: 0px 5px;
}

.textTwo {
  color: white;
  font-size: 14px;
  font-weight: 500;
}


.closeicon {
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.label {

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* grey-6 */

  color: #344054;
  margin-bottom: 5px;
}

.select {
  border: 0.5px solid #D0D5DD;
  border-radius: 6px;
  outline: none;
  height: 50px;

  padding-left: 10px;

  font-size: 20px;
  font-weight: normal;
  color: #8A94A6;

  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;

  display: flex;
  column-gap: 10px;
  align-items: center;
}

.dropDownList {
  position: absolute;
  top: 100%;
  left: 0px;

  width: 100%;
  height: 200px;
  background-color: white;
  z-index: 10;
  border: 0.5px solid #D0D5DD;
  overflow-y: scroll;
}

.dropDownList {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.dropDownList::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.activeItem {
  color: #1B4DF5;
  font-size: 18px;
  font-weight: 500;
  transition: all .4s;
  padding: 5px;
  cursor: pointer;
  padding: 10px;
}

.item {
  color: #344054;
  font-size: 18px;
  font-weight: 500;
  transition: all .4s;
  padding: 10px;
  cursor: pointer;
}

.activeItem:hover, .item:hover {
  background-color: #acbef8;;
}
