.container {
  height: 300px;
  width: 100%;

  padding: 20px;
  background-color: #1B4DF5;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
  align-items: center;
  position: relative;

}

.spiral__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover; 
  height: 100%;
  z-index: 1;
}

.hero__section__text {
  font-size: 50px;
  font-weight: 500;
  line-height: 45px;
  font-family: "Museo";
  color: white;
  text-align: center;
  width: 70%;
  position: relative;
  z-index: 5;
}

.waitList_btn {
  color: #fff;
  padding: 0.9em 1em;
  border-radius: 8px;
  border: 1px solid white;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  text-align: center;
  cursor: pointer;
  width: max-content;

  z-index: 5;
}


/* Responsive adjustments */
@media (max-width: 1000px) {

  .container {
    height: max-content;
    row-gap: 20px;
  }

  .hero__section__text {
    font-size: 35px;
    width: 90%;
  }

}