
.container {
  padding: 20px 40px;
  padding-top: 120px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */
  grid-column: 2/3;
}

.headertext {
  font-family: 'Museo Slab';
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #44444F;
}

.containerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  height: 50px;
  width: 200px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;

  /* margin-top: 20px; */
}


.formContainer {
  width: 100%;
  background-color: white;
  height: min-content;
  margin-top: 20px;
  padding: 20px;
}

.formContainerInner {
  margin-top: 40px;
  width: 500px;

  height: min-content;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.itemOne {
  height: 70px;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.infoText {
  font-family: 'Museo';
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
}


.formContainerTwo {
  width: 100%;
  background-color: white;
  height: 300px;
  margin-top: 40px;
  padding: 20px;
}

.profileContainer {
  margin-top: 20px;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.halfCircle {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  background-color: rgba(199, 197, 197, 0.5); /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.label {
  font-family: 'Museo';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.input {
  border-radius: 6px;
  outline: none;
  height: 50px;
  padding: 0px 10px;

  font-size: 18px;
  font-weight: normal;
  width: 100%;
}

.input {
  border: 0.5px solid #D0D5DD;
}

.itemTest {
  height: 50px;
  width: 100%;

  background-color: #01B9FF;

  display: flex;
  column-gap: 20px;
  align-items: center;

}

.block {
  background-color: #3873FF;
  height: 100%;
  width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: 'Museo';
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: white;
}

.containerThree {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
}

.itemTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBold, .textLight, .passwordText {
  font-family: 'Museo';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.textLight {
  color: #979797;
}

.passwordText {
  color: #3873FF;
}

.mailText {
  font-family: 'Museo';
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;

}

.borderSolid {
  border: 2px solid #D8D8D8;
}

@media (max-width: 1300px) {
  .container {
    grid-column: 1/3;
    padding: 20px;
    padding-top: 120px; 
  }  
}

@media (max-width: 1000px) {
  .headertext {
    font-size: 25px;
    line-height: 25px;
  }

  .containerTwo {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
  }
}

@media (max-width: 800px) {
  .formContainerInner {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .itemTest {
    height: min-content;
    padding: 5px;
  }

  .formContainerTwo {
    height: min-content;
  }
}


@media (max-width: 500px) {

  .itemTwo {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
  }
}