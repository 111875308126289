.container {
  width: 100%;
  height: min-content;
  background-color: white;
  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  align-items: center;
  margin-top: 40px;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 40px;
  font-weight: 400;
  line-height: 30px;
  color: #183887;
  text-align: center;
}

.cardContainer {
  display: flex;
  justify-content: center;
  row-gap: 20px;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-top: 10px;
}

.card {
  width: 350px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #3873FF;

  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
}

.textTwo {
  font-family: 'Museo';
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #142657;
}

.textThree {
  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 70%;
  text-align: center;
}

@media (max-width: 800px) {
  .textOne {
    font-size: 30px;
  } 
}

@media (max-width: 500px) {
  .textOne {
    font-size: 25px;
  } 

  .card {
    width: 90%;
    height: min-content;
    padding: 20px 10px;
  }
}