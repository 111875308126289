.container {
  width: 100%;

  height: min-content;
  display: flex;
  flex-direction: column;
}

.textOne {
  color: #3873FF;
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 500;
  line-height: 19.2px;
  width: 40px;
}

.options {
  border: 1px solid #D9DFE6;
  
  height: 50px;
  width: 100%;
  border-radius: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.containerFour {
  position: absolute;
  left: 20px;

  display: flex;
  column-gap: 10px;
}

.containerTwo {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 40px 0px
}

.textTwo {
  font-family: 'Noto Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #606873;
}