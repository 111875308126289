.textOne {
  font-family: "Museo Slab";
  font-size: 48px;
  font-weight: 400;
  line-height: 57.6px;
  color: #183887;
  text-transform: uppercase;
  text-align: center;
}

.menuBar {
  display: none;
}

.container {
  width: 95%;
  height: 75px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  margin: 0px auto;

  margin-top: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.textOne,
.textOneActive {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #1e1e1e;
  cursor: pointer;
}

.textOneActive {
  color: #183887;
}

.link {
  text-decoration: none;
}

.header {
  height: 80px;
  width: 100%;

  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.66);
  -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.66);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}

.containerTwo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.textOne {
  font-family: "Museo Slab";
  color: #1b4df5;
  font-size: 20px;
}

.containerThree {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #1e1e1e;
  cursor: pointer;
}

.textThree {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  cursor: pointer;
}

.containerFour {
  display: flex;
  align-items: center;
  width: max-content;
  column-gap: 20px;
}

.btnLogin {
  width: 100px;
  height: 45px;
  color: white;
  background-color: #183887;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.btnLoginOutlined {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #3873ff;
  border: 1px solid #3873ff;

  width: 100px;
  height: 45px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.faqs-page {
  display: flex;
  flex-direction: column;
  background: #fff;
  /* min-height: 100vh; */
}

.faqs-container {
  flex: 1;
  padding: 1.5em;
  background-color: #fff;
  margin-left: 5em;
}

.faqs_header {
  font-size: 50px;
  font-weight: 500;
  font-family: "Museo";
  text-align: left;
  color: #142657;
  padding-top: 1em;
  padding-left: 1.5em;
  padding-bottom: 1em;
}

.faq__question__container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.faq_href {
  text-decoration: none;
  font-style: italic;
  color: red;
}

.faq_item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 90%;
}

.faq_item.active {
  max-height: 200px; /* Adjust based on content */
}

.faq_question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #142657;
  font-family: "Open Sans", sans-serif;
}

.faq_arrow {
  font-size: 1em;
}

/* .faq_answer {
  display: none; 
}
.faq_item.active .faq_answer {
  display: block; 
} */

/* .caret__up {
  height: 16px;
  width: 16px;
} */

/* .faq_answer {
  display: none; 
  transition: all 0.3s ease;
}

.faq_item.active .faq_answer {
  display: block; 
} */

.faq_answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: #142657;
  padding-top: 0.5em;
  opacity: 0;
  visibility: hidden;
}

.faq_item.active .faq_answer {
  max-height: 250px;
  opacity: 1;
  visibility: visible;
}

.faqs__button {
  margin-top: 1.5em;
  margin-bottom: 2em;
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

/*FaqsSection Style*/

.faqshero__container {
  background: #142657;
  position: relative;
  height: 444px;
  width: 100%;
  margin-top: 12em;
}

.faqs__spiral {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  object-fit: cover;
  height: 444px;
}

.faqshero__content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  margin-top: -3em;
  margin-bottom: 3em;
  margin-left: 3em;
}

.faqs__cards_i {
  margin-left: -19em;
}

.faqshero__details {
  margin-top: -19em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.faqshero__details_i {
  margin-top: -7em;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.faqshero__input {
  margin-top: -8em;
  width: 440px;
  height: 44px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding-left: 2em;
  position: relative;
  z-index: 3;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .faqs-container {
    margin-left: 2em;
    padding-top: 0px;
  }

  .faqs_header {
    font-size: 40px;
  }
}

@media (max-width: 950px) {
  .textOne {
    font-size: 30px;
    line-height: 37.6px;
  }

  .img {
    height: 400px;
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .container {
    display: none;
    margin: 0px;
  }
}

.menuBar {
  display: none;
}

.sliderContainer {
  height: min-content;
  display: flex;
  justify-content: center;

  margin: 40px auto;
  position: relative;
  width: 80vw;
}

.btnShop {
  position: absolute;
  bottom: 10%;
  right: 20px;

  height: 40px;
  width: 100px;
  background-color: white;
  outline: none;
  border: none;
  border: 1px solid #1b4df5;
  border-radius: 5px;

  color: #3873ff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.slider {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0px auto;
  position: relative;
}

.hiddenHeight {
  height: 100px;
  width: 10px;

  display: none;
}

@media (max-width: 1300px) {
  .sliderContainer {
    /* width: 90vw; */
    height: 400px;
  }

  .btnShop {
    right: 50px;
  }
}

@media (max-width: 1000px) {
  .menuBar {
    display: block;
    height: 30px;
    width: 30px;
  }

  .btnShop {
    right: 50px;
    bottom: -15%;
  }

  .hiddenHeight {
    display: block;
  }

  .containerFour,
  .textTwo {
    display: none;
  }

  .header {
    padding: 0px 30px;
  }

  .slider {
    height: 400px;
  }

  .sliderContainer {
    height: 300px;
  }
}

@media (max-width: 900px) {
  .btnShop {
    right: 50px;
    bottom: -5%;
  }
}

@media (max-width: 800px) {
  .sliderContainer,
  .slider {
    height: 200px;
  }

  .hiddenHeight {
    display: none;
  }

  .btnShop {
    right: 15%;
    bottom: 10%;
  }
}

@media (max-width: 500px) {
  .slider {
    margin-top: -20px;
  }

  .btnShop {
    right: 15%;
    bottom: 35%;

    height: 35px;
    width: max-content;
    padding: 0px 10px;
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  .btnShop {
    right: 5%;
    bottom: 40%;
  }
}

@media (max-width: 950px) {
  .container {
    height: min-content;
  }
  .cardContainer {
    flex-direction: column;
    row-gap: 20px;
    height: min-content;
  }

  .textOne {
    font-size: 24px;
    line-height: 23px;
  }
}
