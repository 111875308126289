.drawer, .drawerOpen {
  position: fixed;
  top: 0;
  left: -100%; /* Initially off-screen */
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease-in-out;
}

/* Slide-in effect */
.drawerOpen {
  transform: translateX(100%);
}


.drawerContent {
  padding: 30px;
  height: 80vh;
  width: 50vw;
  background-color: white;
  border-radius: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 30px;
  font-weight: 500;
  line-height: 28.8px;
  color: #183887;
}

.containerFour {
  width: 80%;
  display: flex; 
  column-gap: 20px;
}

.input, .inputTwo {
  border-radius: 15px;
  outline: none;
  height: 45px;
  padding: 0px 10px;

  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: #5c5b5b;

  border: 0.5px solid #CCCCCC;
  padding-left: 20px;
}

.inputTwo {
  width: 50%;
}

.input {
  width: 80%;
}

.dropDownItem {
  width: 80%;
  height: 45px;
}

.btn {
  height: 50px;
  width: 80%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;
}

@media (max-width: 1000px) {  

  .drawerContent {
    width: 80vw;
    row-gap: 20px;
  }

  .textOne {
    font-size: 25px;
  }
  
  .input, .dropDownItem, .containerFour, .btn {
    width: 99%;
  }

}

@media (max-width: 700px) {  

  .containerFour {
    flex-direction: column;
    row-gap: 20px;
  }

  .inputTwo, .input {
    width: 100%;
    font-size: 14px;
  }
}