* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.verifymain__container {
  background: #eff0f6;
  height: 100%;
}

.verifymain__content {
  display: flex;
  justify-content: space-around;
  /* margin-top: 2.5em; */
}

.verifymain__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.verifycard {
  background: #fff;
  width: 556px;
  height: 450px;
  border-radius: 5px;
  margin-bottom: 2em;
  margin-top: 2em;
}

.verifymain__discount {
  padding-top: 1.5em;
  padding-left: 1.5em;
  color: #000000;
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 600;
}

.verifymain__input__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 2.5em;
}

.verifymain__input {
  width: 494px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  margin-bottom: 2.5em;
  background: #fff;
  padding-left: 1.5em;
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 400;
}

.verifymain__button {
  width: 486px;
  height: 59px;
  border-radius: 5px;
  color: #fff;
  background: #3873ff;
  margin-top: 2em;
  font-family: "Museo Slab";
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  border: none;
}

.verifymain__button:hover {
  background-color: #0c2d7c;
}

.verifymain__faqs {
  background: #fff;
  width: 556px;
  height: 450px;
  border-radius: 5px;
  border: none;
  margin-bottom: 2em;
  /* margin-top: 5em; */
}

.containerTwo {
  background-color: white;
  height: 80px;
  width: 550px;
  border-radius: 6px;

  border: 1px solid #eeeeee;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  /* margin-bottom: 50px; */
}

.leftSide {
  background-color: #ff8a00;
  width: 5px;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  position: absolute;
  left: 0px;
  top: 0px;
}

.containerFour {
  display: flex;
  column-gap: 20px;
}

.verifyText {
  font-family: "Aeonik";
  letter-spacing: 0.5px;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color: #44444f;
}

.uploadtext {
  font-family: "Aeonik TRIAL";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 14.82px;
  color: #848484;
}

.btn {
  height: 50px;
  background-color: #3873ff;
  width: 140px;
  border-radius: 6px;
  color: white;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}

.btn:hover {
  background-color: #0c2d7c;
}

.verifymain_codes__container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 231px;
}

.verifymain__codes__content {
  background: #fff;
  margin-top: 1em;
  width: 556px;
  height: 494px;
  border-radius: 5px;
  border: none;
}

.verifymain__homepic {
  margin-top: 1em;
}

.verifymain__homepic {
  width: 95%;
  object-fit: cover;
}

.verifymain__faqs__text {
  padding-top: 1.5em;
  padding-left: 1.5em;
  color: #000000;
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.hr {
  color: #ccc;
}

.verifymain__faqs__question {
  font-family: "Aeonik TRIAL";
  font-size: 18px;
  font-weight: 800;
  color: #000;
  padding-top: 1em;
  padding-left: 1.5em;
}

.verifymain__faqs__questions {
  font-family: "Aeonik TRIAL";
  font-size: 15px;
  font-weight: 400;
  color: #484848;
  padding-top: 0.5em;
  padding-left: 1.5em;
  margin-bottom: 1em;
}

/* VerifyCodes Styles */

.container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.titleText {
  font-family: "Aeonik TRIAL";
  font-size: 24px;
  letter-spacing: 0.5px;
  font-weight: 900;
  color: #44444f;
}

.titleTextTwo {
  font-family: "Aeonik TRIAL";
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 900;
  color: #44444f;
}

.containerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.hightlight {
  background-color: #cef2ff;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.test {
  height: 80px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  list-style-type: none;
}

li {
  border-top: 1px solid #ccc;
}

li:last-child {
  border-bottom: 1px solid #ccc;
}

.percent {
  font-family: "Aeonik TRIAL";
  font-size: 24px;
  font-weight: 800;
  line-height: 27.36px;
  letter-spacing: 0.07em;
  color: #01b9ff;
}

.containerFour {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.textTwo {
  font-family: "Aeonik TRIAL";
  font-size: 16px;
  font-weight: 400;
  line-height: 18.24px;
  letter-spacing: 0.07em;
  color: #838383;
}

.code {
  font-family: "Aeonik TRIAL";
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;

  text-decoration: underline;
  text-underline-offset: 2px;
  color: #e1e1e1;

  text-align: center;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .verifymain__content {
    display: flex;
    flex-direction: column;
  }

  .verifymain__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .verifycard {
    width: 100%;
    height: 450px;
    margin-top: 5em;
  }

  .container {
    width: 90%;
  }

  .containerTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
  }

  .verifymain__input {
    width: 90%;
    height: 50px;
  }

  .verifymain__button {
    width: 90%;
    height: 59px;
  }

  .verifymain__faqs {
    display: none;
  }

  .verifymain__codes__content {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .verifymain__homepic {
    display: block;
    width: 90%;
    margin: 2em auto;
  }
}

@media (max-width: 1300px) {
  .verifymain__content {
    display: flex;
    flex-direction: column;
  }

  .verifymain__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .verifycard {
    width: 100%;
    height: 450px;
    margin-top: 5em;
  }

  .container {
    width: 90%;
  }

  .containerTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
  }

  .verifymain__input {
    width: 90%;
    height: 50px;
  }

  .verifymain__button {
    width: 90%;
    height: 59px;
  }

  .verifymain__faqs {
    display: none;
  }

  .verifymain__codes__content {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .verifymain__homepic {
    display: block;
    width: 90%;
    margin: 2em auto;
  }
}

@media (max-width: 500px) {
  .verifymain__content {
    display: flex;
    flex-direction: column;
  }

  .verifymain__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .verifycard {
    width: 100%;
    height: 450px;
    margin-top: 5em;
  }

  .container {
    width: 90%;
  }

  .containerTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
  }

  .verifymain__input {
    width: 90%;
    height: 50px;
  }

  .verifymain__button {
    width: 90%;
    height: 59px;
  }

  .verifymain__faqs {
    display: none;
  }

  .verifymain__codes__content {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .verifymain__homepic {
    display: block;
    width: 90%;
    margin: 2em auto;
  }
}

@media (max-width: 350px) {
  .verifymain__content {
    display: flex;
    flex-direction: column;
  }

  .verifymain__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .verifycard {
    width: 100%;
    height: 450px;
    margin-top: 5em;
  }

  .verifymain__input {
    width: 90%;
    height: 50px;
  }

  .containerTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
  }

  .verifymain__button {
    width: 90%;
    height: 59px;
  }

  .verifymain__codes__content {
    display: block;
    width: 90%;
    margin: 0 auto;
    width: 100%;
  }

  .verifymain__homepic {
    display: block;
    width: 90%;
    margin: 2em auto;
  }

  .verifymain__faqs {
    display: none;
  }
}
