.container {
  height: 400px;
  width: 100%;

  background-color: #333333;
  padding: 30px;

  display: flex;
  justify-content: space-between;
}

.containerIcon {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

}

.containerTwo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.textOne {
  font-family: 'Museo Slab';
  color: #1B4DF5;
  font-size: 20px;
}

.containerThree {
  width: 100%;
  height: 50px;
  background: #183887;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.circleContainerTwo {
  display: flex;
  column-gap: 20px;
}

.textContainerTwo {
  display: flex;
  column-gap: 90px;
}

.textTitle {
  font-family: "Museo Slab";
  font-size: 23px;
  font-weight: 600;
  line-height: 20px;
  color: white;
  margin-bottom: 10px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}

.smallText {
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: .5px;
  color: white;
}

.smallTextTwo {
  font-family: "Museo Slab";
  font-size: 18px;
  font-weight: 600;
  line-height: 15px;
  color: white;
  letter-spacing: .5px;
}

.textContainer2 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.textContainer3 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.textContainer4 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.circle {
  height: 20px;
  width: 20px;
  background-color: white;

  border-radius: 50%;
}

.circleContainer {
  display: none;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 17px;
  font-weight: 500;
  line-height: 15px;
  color: white;
}

.textTwo2 {
  font-family: "Museo Slab";
  font-size: 17px;
  font-weight: 500;
  line-height: 15px;
  color: white;
}

@media (max-width: 1200px) {  
  .textContainer4 {
    display: none;
  }
}

@media (max-width: 800px) {  
  .textContainer3 {
    display: none;
  }

  .circleContainerTwo, .textTwo2 {
    display: none;
  }

  .containerThree {
    justify-content: center;
  }
}

@media (max-width: 600px) {  
  .textContainer2 {
    display: none;
  }

  .textContainer {
    border-top: 2px solid white;
    border-bottom: 2px solid white;

    padding: 20px 0px;
  }

  .container {
    flex-direction: column;
    height: min-content;
    row-gap: 20px;
  }

  .circleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}