.container {
  padding: 20px;

  height: 500px;
  width: 100%;
  background-color: white;

  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.partOne {
  width: 50%;
  height: 100%;

  display: flex;
  justify-content: center;
}

.content {
  width: 80%;
  height: 100%;
}

.contentItem {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #D7DAE0;
  cursor: pointer;
  width: 85%;
  padding-top: 10px;

  transition: all 0.3s ease;
}

.contentItem.active {
  max-height: 200px;
}

.partTwo {
  width: 50%;
  height: 400px;

  display: flex;
  justify-content: center;
}

.partTwoInner {
  width: 80%;
  height: 90%;

  display: flex;
  justify-content: center;
}

.image36 {
  width: 100%;
  height: 100%;
}

.hero__section__text {
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  font-family: "Museo";
  color: #141D57;
}

.smallText {
  font-size: 18px;
  letter-spacing: .5px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Aeonik";
  color: #667085;

  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.contentItem.active .smallText {
  max-height: 100px; /* Adjust based on content */
  opacity: 1;
  visibility: visible;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    row-gap: 20px;

    height: max-content;
  }

  .partOne {
    width: 100%;
    height: max-content;
    justify-content: flex-start;
  }

  .partTwo {
    width: 100%;
    height: 400px;
    margin-top: 10px;
  }

  .content, .contentItem {
    width: 100%;
  }

  .partTwoInner {
    width: 90%;
    height: 90%;
  }

}