
.container {
  padding: 20px 40px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */

  background-color: white;
  min-height: 100vh;
  /* overflow-y: scroll; */
}

.header {
  height: 50px;
  width: 100%;

  display: flex;

  justify-content: end;
  align-items: center;
}

.card {

  width: 400px;
  height: 450px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 0 auto;
  box-shadow: 0px 6px 8px -1px rgba(0,0,0,0.97);
  -webkit-box-shadow: 0px 6px 8px -1px rgba(0,0,0,0.97);
  -moz-box-shadow: 0px 6px 8px -1px rgba(0,0,0,0.97);

  margin-top: 40px;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.textOne {
  font-family: 'Museo';
  font-size: 25px;
  font-weight: 600;
  text-align: center;

}

.photodark {
  background-color: #4D607C;
  height: 165px;
  width: 165px;
  border-radius: 5px;
}

.inputContainer {
  background-color: #F8FCFF;
  height: 50px;
  width: 265px;

  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
}

.inputOne {
  border: 1px solid #3873FF;
  border-radius: 6px;
  height: 35px;
  width: 120px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  cursor: pointer;
}

.inputTwo {
  width: 100%;
  height: 100%;

  position: absolute;
  opacity: 0;
}

.textTwo {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #3C3C3C;
}

.companyLogo {
  height: 165px;
  width: 165px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  /* border-radius: 50%; */
}

.btn {
  height: 50px;
  width: 80%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;
}

.filetext {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #3873FF;
}

@media (max-width: 1300px) {  
  .header {
    justify-content: start;
  }
}


@media (max-width: 800px) {  
  .card {
    width: 90%  
  }

  .inputContainer {
    width: 80%;
    height: min-content;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
  }

  .photodark, .companyLogo {
    width: 80%;
  }

  .textOne {
    font-size: 20px;
  }
}


@media (max-width: 500px) {  
  .card {
    width: 100%  
  }

  .inputContainer {
    width: 90%;
  }

  .photodark, .companyLogo {
    width: 90%;
  }

}

