.container {
  height: 100vh;
  width: 100%;
  background-color: white;
  overflow-y: scroll;
}

.header {
  height: 80px;
  width: 100%;

  box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.66);
  -webkit-box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.66);
  -moz-box-shadow: 0px 2px 10px -2px rgba(0,0,0,0.66);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}

.containerTwo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.textOne {
  font-family: 'Museo Slab';
  color: #1B4DF5;
  font-size: 20px;
}

.containerThree {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.textTwo {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #1E1E1E;
  cursor: pointer;
}

.textThree {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  cursor: pointer;
}

.containerFour {
  display: flex;
  align-items: center;
  width: max-content;
  column-gap: 20px;
}

.btnLogin {
  width:  100px;
  height: 45px;
  color: white;
  background-color: #183887;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.btnLoginOutlined {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #3873FF;
  border: 1px solid #3873FF;

  width:  100px;
  height: 45px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.menuBar {
  display: none;
}



@media (max-width: 1000px) {
  .menuBar {
    display: block;
    height: 30px;
    width: 30px;
  }
  .containerFour, .textTwo {
    display: none;
  }

  .header {
    padding: 0px 30px;
  }
}

