.container {
  height: min-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin-bottom: 40px;
  position: relative;


  background-image: url("../../assets/images/backImage.svg");
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  background-size: 800px 800px;
}


.textOne {
  font-family: "Museo Slab";
  font-size: 40px;
  font-weight: 400;
  color: #183887;
}

.textTwo {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: white;
}

.mobileText {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 80%;
  display: none;
  margin-left: 20px;
  padding-bottom: 10px;
}

.containerTwo {
  width: 70%;
  height: 500px;
  position: relative;
  /* background-color: aqua; */
}

.cardOne {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 450px;
  height: 200px;
  border-radius: 10px;
  background-color: #183887;
  padding: 10px 15px;
}

.cardTwo {
  position: absolute;
  top: 300px;
  left: 200px;
  width: 450px;
  height: 150px;
  background-color: #183887;
  border-radius: 10px;
  padding: 10px 15px;
}

.cardThree {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 450px;
  height: 200px;
  background-color: #183887;
  border-radius: 10px;
  padding: 10px 15px;
}

@media (max-width: 1400px) {
  .containerTwo {
    width: 80%;
  }  
}

@media (max-width: 1200px) {
  .containerTwo {
    width: 90%;
  }  
}

@media (max-width: 1150px) {
  .containerTwo {
    display: none;
  }  

  .mobileText {
    display: block;
  }

  .container {
    background-size: 500px 500px;
  }

}


@media (max-width: 800px) {
  .textOne {
    font-size: 30px;
  } 
  
  .mobileText {
    width: 90%;
  }

  .container {
    background-size: 300px 300px;
  }

}

@media (max-width: 500px) {
  .container {
    margin-top: -50px;
  }

  .textOne {
    font-size: 25px;
  } 
}