.carousel {
  position: relative;
  min-width: 100%;

  height: 200px;
  overflow: hidden;
  /* background-color: grey; */
}

.content {
  width: min-content;
  display: flex;
  overflow: hidden;
  column-gap: 20px;
  transition: transform 0.5s ease;

}


.carousel-slide {
  min-width: 300px;
  height: 200px;
  background-color: #01B9FF;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  row-gap: 0px;
  padding: 10px;
}



.carousel-button {
  position: absolute;
  top: 60px;
  font-size: 24px;
  background: white;
  height: 50px;
  width: 50px;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  /* z-index: 10; */
}

.carousel-button.prev {
  left: 20px;
  
  rotate: 180deg;
}

.carousel-button.next {
  left: 70vw;
}


.testBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  /* z-index: 10; */
  left: 70vw;
}

.textOne {
  font-family: 'Aeonik TRIAL';
  font-size: 48px;
  font-weight: 700;
  line-height: 54.72px;
  color: white;
}

.textTwo {
  font-family: 'Aeonik TRIAL';
  font-size: 18px;
  font-weight: 400;
  line-height: 18.24px;
  color: white;
}