.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
}



.label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* grey-6 */

  color: #344054;
  margin-bottom: 5px;
}

.input, .errorInput {
  border-radius: 6px;
  outline: none;
  height: 45px;
  padding: 0px 10px;

  font-size: 18px;
  font-weight: normal;
  color: #8A94A6;
}

.input {
  border: 0.5px solid #D0D5DD;
}

.errorInput {
  border: 0.5px solid #F04438;
}

.errorText {
  color: #F04438;
  font-size: 12px;
  font-weight: normal;

  position: absolute;
  left: 0px;
  bottom: -20px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.showPassword {
  position: absolute;
  top: 32px;
  right: 25px;
  transform: translateY(50%);

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #505050;

  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .container {
    /* height: 90%; */
  }
  .input {
    /* height: 40px; */
    font-size: 16px;
  }

  .showPassword {
    top: 28px;
  }

  .label {
    font-size: 16px;
  }
}
