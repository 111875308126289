.container {
  width: 100vw;

  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 60px 1fr;
}

@media (max-width: 1300px) {
  .container {  
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px 1fr;
  }
  
}

