.container {
  padding: 20px 40px;
  padding-top: 120px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */
  grid-column: 2/3;
}

.headertext {
  font-family: 'Museo Slab';
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #44444F;
}

.containerThree {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: center; */
}

.containerTwo {
  background-color: white;
  height: 80px;
  width: 550px;
  border-radius: 6px;

  border: 1px solid #EEEEEE;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
}

.leftSide {
  background-color: #FF8A00;
  width: 5px;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  position: absolute;
  left: 0px;
  top: 0px;
}

.containerFour {
  display: flex;
  column-gap: 20px;
}

.verifyText {
  font-family: 'Aeonik';
  letter-spacing: .5px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  color: #44444F;
}

.uploadtext {
  font-family: 'Aeonik TRIAL';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: .5px;
  line-height: 14.82px;
  color: #848484;
}

.btn {
  height: 50px;
  background-color: #3873FF;
  width: 140px;
  border-radius: 6px;
  color: white;

  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .4s;
}

.btn:hover {
  background-color: #0c2d7c;
}

.containerSix {
  margin-top: 20px;
  width: 100%;
  height: 600px;

  display: flex;
  column-gap: 25px
}

.cardContainer {
  width: 40%;
  height: min-content;

  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.discount {
  width: 100%;

  height: 100px;
  background-color: white;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.seeText {
  font-family: 'Aeonik TRIAL';
  color: #3873FF;
  font-size: 18px;
  cursor: pointer;
  transition: all .4s;
  text-decoration: underline;
  text-underline-offset: 3px;
  letter-spacing: .5px;
}

.seeText:hover {
  color: #0e4de0;
}

.discountText {
  font-family: 'Aeonik TRIAL';
  font-size: 20px;
  font-weight: 400;
}

.moneyText {
  font-family: 'Aeonik TRIAL';
  font-size: 30px;
  font-weight: 800;
  line-height: 14.1px;
  letter-spacing: 1px;
  color: #44444F;
}

.smallMoneyText {
  font-size: 16px;
}

.earned {
  width: 100%;
  height: min-content;
  background-color: white;
  border-radius: 6px;
}


.historyContainer {
  width: 60%;

  height: min-content;

  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.history {
  width: 100%;

  height: min-content;
  background-color: white;
  border-radius: 6px;
}

.advert {
  height: 250px;
  width: 100%;

  border-radius: 6px;
}

.homePics {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.mobileContainer {
  display: none;
}

.textprofile {
  font-family: 'Museo Slab';
  color: #44444F;
  font-size: 24px;
  font-weight: 550;
  letter-spacing: .5px;
}

.idText {
  font-family: 'Aeonik TRIAL';
  font-size: 12px;
  font-weight: 400;
  line-height: 13.68px;
}

.upgradetext {
  font-family: 'Aeonik TRIAL';
  color: #CD7F32B2;
  font-size: 15px;
  cursor: pointer;
  transition: all .4s;
}

.upgradetext:hover {
  color: #e2b383b2;
}

.span {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.date {
  color: #FF000099;
  font-family: 'Aeonik TRIAL';
  font-size: 15px;
}

@media (max-width: 1300px) {
  .container {
    grid-column: 1/3;
    padding: 20px;
    padding-top: 120px; 
  }  

  .mobileContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1100px) {
  .headertext {
    font-size: 25px;
    line-height: 25px;
  }
  
  .containerThree {
    flex-direction: column;
    row-gap: 10px; 
  }

  .containerSix {
    flex-direction: column;
    row-gap: 20px;
  }

  .cardContainer, .historyContainer, .containerTwo {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .containerTwo {
    flex-direction: column;
    row-gap: 20px;
    height: min-content;
    align-items: flex-start;
  }

  .containerFour {
    column-gap: 10px;
  }

  .moneyText {
    font-size: 20px;
  }
  
}
