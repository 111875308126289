
.container {
  padding: 20px 40px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */

  background-color: #3873FF;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  
  /* overflow-y: scroll; */
}

.card {

  width: 400px;
  height: 350px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 0 auto;
  box-shadow: 0px 6px 8px -1px rgba(0,0,0,0.97);
  -webkit-box-shadow: 0px 6px 8px -1px rgba(0,0,0,0.97);
  -moz-box-shadow: 0px 6px 8px -1px rgba(0,0,0,0.97);
  background-color: white;

  display: flex;
  justify-content: center;
  row-gap: 30px;
  flex-direction: column;
  align-items: center;
}

.textOne {
  font-family: 'Museo';
  font-size: 25px;
  font-weight: 600;

}

.photodark {
  background-color: #4D607C;
  height: 165px;
  width: 165px;
  border-radius: 5px;
}

.inputContainer {
  background-color: #F8FCFF;
  height: 50px;
  width: 265px;

  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
}


.textTwo {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  color: #818181;
}

.btn {
  height: 50px;
  width: 80%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;
}
