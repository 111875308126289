.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin: 20px 0px;
  padding: 0px 20px;
}

.containerTwo, .containerThree {
  display: flex;
  justify-content: space-between;
  height: min-content;
}

.containerFour {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: min-content;
}

.item1 {
  width: 50%;
  height: min-content;

  display: flex;
  justify-content: center;
}

.item2 {
  width: 50%;
  height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.imgOne {
  width: 500px;
  height: 400px;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 30px;
  font-weight: 550;
  line-height: 40px;
  text-align: center;
  color: #183887;
  width: 70%;
}

.textTwo {
  font-family: "Museo Slab";
  font-size: 25px;
  font-weight: 550;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;

  color: #183887;
  width: 80%;
  margin: 20px auto;
}

.btnLogin {
  width:  200px;
  height: 50px;
  color: white;
  background-color: #183887;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;

  margin: 0px auto;
  margin-bottom: 20px;
}


@media (max-width: 1000px) {
  .containerTwo {
    flex-direction: column-reverse;
    row-gap: 30px;
    align-items: center;
  }

  .containerThree, .containerFour {
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
  }

  .item2, .item1 {
    width: 90%;
  }

  .item2 {
    height: min-content;
  }

  .textOne, .textTwo {
    font-size: 25px;
    line-height: 30px;
    width: 100%;
  }

  .imgOne {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 500px) {
  .container {
    margin-top: -30px;
  }

  .textOne, .textTwo {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 450px) {
  .container {
    margin-top: -40px;
  }
}

@media (max-width: 400px) {
  .container {
    margin-top: -60px;
  }

  .imgOne {
    width: 100%;
    height: 250px;
  }
}


@media (max-width: 300px) {
  .imgOne {
    width: 100%;
    height: 200px;
  }
}

