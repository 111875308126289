.container {
  width: 100%;

  height: min-content;
  background-color: #183887;
  padding: 40px 20px;
}

.textOne {
  font-family: "Museo Slab";
  font-size: 40px;
  font-weight: 400;
  line-height: 30px;
  color: white;
  text-align: center;
}

.itemContainer {
  height: 45px;
  border: 1px solid #183887;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  cursor: pointer;
  column-gap: 10px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  align-items: center;
  margin-top: 40px;
}

.containerTwo {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;

  margin: 0 auto;
}


.textTwo {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.cardContainer {
  display: flex;
  /* justify-content: center; */
  /* row-gap: 20px; */
  /* flex-wrap: wrap; */
  column-gap: 20px;
  margin-top: 10px;
  width: 96vw;
  overflow-x: scroll;
  scrollbar-width: none;
  margin: 0 auto;
}

.card {
  width: 236px;
  height: 139px;
  background-color: white;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}


.img {
  width: 236px;
  height: 130px;
  object-fit: fill;
}



@media (max-width: 800px) {
  .textOne {
    font-size: 30px;
  } 

  .card {
    padding: 0px 5px;
  }

  .containerTwo {
    flex-direction: row;
    flex-wrap: nowrap;
    width: min-content;
  }
}

@media (max-width: 500px) {
  .textOne {
    font-size: 25px;
  }

}