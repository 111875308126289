* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.businesssidebar__container {
  background: #fff;
  height: 100%;
}

.businesssidebar__text {
  padding: 2em 0em 0.5em 3em;
}

.businesssidebar__context {
  display: flex;
  padding-left: 3em;
}

.businesssidebar__verify {
  font-family: "Museo Slab";
  font-size: 15px;
  font-weight: 400;
  color: #3873ff;
  padding-bottom: 1em;
}

.businesssidebar__verify {
  padding-left: 10px;
}

.businesssidebar__content {
  display: flex;
  margin-top: 1.5em;
  margin-left: 3em;
  margin-bottom: 2em;
  cursor: pointer;
}

.businesssidebar__account__text {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  color: #000;
  padding-left: 10px;
}

.businesssidebar__account__text_ii {
  font-family: "Museo Slab";
  font-size: 20px;
  font-weight: 400;
  color: red;
  padding-left: 10px;
}

.businesssidebar__section {
  margin-top: 20em;
}

.active {
  color: #3873ff;
  background: red;
}
