.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
}

.select {
  border: 0.5px solid #CCCCCC;
  padding-left: 20px;
  border-radius: 15px;
  outline: none;
  height: 100%;
  width: 100%;

  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: #5c5b5b;

  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
}

.container::after {
  content: "\2304";
  font-size: 25px;
  line-height: 23px;
  color: #D0D5DD;

  position: absolute;
  right: 15px;
  top: 5px;
}

@media screen and (max-width: 700px) {
  .select {
    font-size: 14px;
  }

}
