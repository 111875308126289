.containerMain {
  width: 100%;
  height: max-content;
  background-color: white;
}
.container {
  width: 80%;
  height: max-content;

  margin: 0 auto;
  padding-top: 50px;
  margin-bottom: 40px;
}

.join__postpaid__text {
  color: #142657;
  font-size: 50px;
  font-family: "Museo";
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin: 0 auto !important;
  line-height: 60px;
}

.cardContainer {
  width: 100%;
  height: max-content;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.cardItem {
  width: 100%;
  height: 150px;
  background-color: #F6F7F9;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-radius: 8px;
}

.innerHeight {
  height: 50px;
  width: 30px;
}

.image {
  width: 50px;
  height: 50px;
}

.cardtext {
  font-size: 16px;
  font-weight: 400;

}

.percentText {
  color: #1C66F4;
  font-size: 35px;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .container {
    width: 90%;
  }


  .cardContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .join__postpaid__text {
    font-size: 35px;
  }

  .percentText {
    font-size: 30px;
  }
  
}

@media (max-width: 600px) {
  .cardContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  
}