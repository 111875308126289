

.container {
  padding: 20px 40px;  /* To offset the fixed header height*/
  /* margin-left: 250px; */

  background-color: white;
  min-height: 100vh;
  /* overflow-y: scroll; */
}

.header {
  height: 50px;
  width: 100%;

  display: flex;

  justify-content: end;
  align-items: center;
}

.containerTwo {
  margin-top: 20px;
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
}

.sideLogoContainer, .formContainer {
  display: flex;
  justify-content: center;
}

.formInner {
  width: 600px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
}

.textContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

}

.textOne {
  font-family: 'Museo';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  margin-bottom: 10px;
}

.textTwo {
  font-family: 'Museo Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #4B4B4B;

}

.input {
  border-radius: 15px;
  outline: none;
  height: 45px;
  padding: 0px 10px;

  font-family: 'Museo Slab';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #B3B3B3;

  border: 0.5px solid #CCCCCC;
  padding-left: 30px;
}

.input {
  width: 80%;
}


.btn {
  height: 50px;
  width: 80%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: white;
  background-color: #3873FF;
}

@media (max-width: 1300px) {  
  .header {
    justify-content: start;
  }

  .container {
    padding: 20px 20px;
  }

  .containerTwo {
    grid-template-columns: 1fr;
    justify-content: center;
    margin-top: 100px;
  } 

  .sideLogoContainer {
    display: none;
  }

  .formInner {
    width: 100%;
  }

  .input, .btn {
    width: 100%;
  }

  .textOne {
    font-size: 25px;
  }
  
}